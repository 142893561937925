@media print {
  body {
    background-color: #FFF;
    color-adjust: exact !important;  
    -webkit-print-color-adjust: exact !important; 
    print-color-adjust: exact !important; 
  }

  *{ 
    color-adjust: exact !important;  
    -webkit-print-color-adjust: exact !important; 
    print-color-adjust: exact !important; 
  }

  .d-text-blue-light {
    color: #2e99d8 !important;
  }

  .d-text-white {
    color: #FFFFFF !important;
  }

  #root > .uk-grid {
    display: none;
  }
}

body {
  margin: 0;
  background-color: #f5f7fa;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #404e67;
  -webkit-print-color-adjust: exact;
}

h1,
h2,
h3,
h4,
h5,
h6,
.d-font-mon,
.d-card-header .d-card-header-left {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uk-input,
.uk-select,
.uk-textarea {
  border-radius: 5px !important;
}

.d-pointer {
  cursor: pointer;
}

.d-vertical-align-top {
  vertical-align: top;
}

.d-table-container {
  overflow-x: auto;
}

input.uk-input, 
textarea.uk-textarea,
select.uk-select {
  font-size: 16px;
}

.d-text-blue-light {
  color: #2e99d8;
}

.d-text-blue {
  color: blue !important;
}

.d-text-green {
  color: green !important;
}

.d-text-pink {
  color: magenta !important;
}

.d-text-red {
  color: red !important;
}

.d-text-purple {
  color: purple !important;
}

.d-text-white {
  color: #ffffff !important;
}

.d-text-black {
  color: #000 !important;
}

.d-text-yellow {
  background-color: #ffda7d;
}

.d-width-100 {
  width: 100%;
}

sup {
  color: inherit !important;
}

.d-border {
  border: 1px solid #bcbcbc;
}

.d-button-close {
  cursor: pointer;
}
.d-page-break {
  display: block;
  page-break-before: always;
}

.d-page-loadings-login {
  height: 100vh;
}

.d-side-menu {
  background: #404e67;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  overflow: auto;
}

.d-side-menu li {
  margin-top: 0 !important;
}

.d-side-menu .d-side-menu-show-hover {
  margin-left: 4px;
}

.d-side-menu .d-side-menu-title {
  padding: 8px 20px;
  color: #bcbcbc;
  font-size: 13px;
}

.d-side-menu a {
  color: #dcdcdc;
  padding: 8px 20px;
  display: block;
  padding-left: 40px;
}

.d-side-menu a.d-side-menu-active,
.d-side-menu a:focus {
  color: #00b5b8 !important;
  font-weight: 500;
  background-color: #38445a;
}
.d-side-menu a:hover,
.d-side-menu a:focus {
  text-decoration: none;
}

.d-side-menu a:hover {
  color: #c3c3c3;
  background-color: #38445a;
}

.d-side-menu a > span {
  margin-right: 12px;
}

.d-header-menu-dropdown a {
  padding: 5px 20px;
  display: block;
}

.d-header-menu-dropdown a > span {
  margin-right: 12px;
}

.d-header-menu-dropdown li {
  margin: 0 !important;
}

#d-member-background-login {
  min-height: 100vh;
  background-image: url("/assets/bg-2.jpg");
}

#d-consult-background-login {
  min-height: 100vh;
  background-image: url("/assets/bg-consult.jpeg");
}

#d-moderator-background-login {
  min-height: 100vh;
  background-image: url("/assets/bg-3.jpg");

}

.d-user-profile-card-image {
  height: 100px;
  width: 100px;
  border-radius: 75px;
}

.d-smart-health-consult-image {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 5px;
  background-color: #e4e7ed;
}

.d-background-white {
  background-color: #ffffff;
}

.uk-button-secondary {
  color: #FFF !important;
}

.uk-button-yellow {
  background-color: #ffda7d;
  color: #000 !important;
  border: 1px solid #FFBF00 !important;
  
}

.uk-button-yellow:hover {
  background-color: #FFE873;

}

.uk-button-blue {
  background-color: #2e99d8;
  color: #FFF !important;
  border: 1px solid #1976D2 !important;
}

.uk-button-blue:hover {
  background-color: #2196F3;
}

.d-icon-card {
  vertical-align: middle;
  height: 80px;
}

.d-icon-card * {
  color: #ffffff;
}

.d-icon-card-icon {
  margin-top: 0.2em;
  font-size: 2em;
  text-align: center;
  vertical-align: middle;
}

.d-icon-card-title {
  font-size: 1.2rem;
}

.d-icon-card-value {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding-top: 12px;
}

.d-icon-table {
  background-color: #009c9f;
  color: #fff;
  padding: 2px;
  border: 2px solid #fff;
  box-shadow: 0 0 3px 0 #555555;
}

.d-icon-table.d-icon-table-minus {
  background-color: #ff7588;
}

.d-card-header {
  font-size: 20px;
  font-weight: 500;
}

.d-card-header .d-card-header-left {
  width: 70%;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}

.d-card-header .d-card-header-right {
  width: 30%;
  display: inline-block;
  text-align: right;
}

.d-card-header .d-card-header-right span:first-child {
  margin-right: 20px;
}

.d-card-header .d-card-header-right > span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.d-card-header .d-card-header-right > span:hover {
  color: #1b2942;
}

.d-background-blue {
  background-color: #1976D2 !important;
}

.d-background-yellow {
  background-color: #ffda7d !important;
}

.d-background-yellow-light {
  background-color: #FFFFE0;
}

.d-background-orange {
  background-color: #ffa87d !important;
}

.d-background-red {
  background-color: #ff7588 !important;
}

.d-background-green {
  background-color: #16d39a !important;
}

.d-background-green-2 {
  background-color: #009c9f !important;
}

.d-background-gray {
  background-color: #e4e7ed !important;
}

.d-background-dark {
  background-color: #1b2942 !important;
}


.d-background-dark-2 {
  background-color: #404e67 !important;
}


.d-color-white {
  color: #ffffff !important;
}

#d-chat-modal-consult-detail {
  position: fixed;
  top: 10%;
  width: 50%;
  z-index: 100;
  width: 340px;
  left: 50%;
  margin-left: -170px;
  padding: 10px;
  background-color: #ffffff;
  min-height: 200px;
  box-shadow: 0 0 10px -5px #555555;
}

.d-chat-icon-online {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #16d39a;
  display: block;
  position: absolute;
  border: 2px solid #ffffff;
  right: 0px;
  top: 0px;
}
.d-chat-icon-offline {
  background-color: #555555;
}


@media (max-width: 1200px) {
  #d-chat-list-side-overlay {
    background-color: rgba(0, 0, 0, 0.15);
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  #d-chat-list-side {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 90%;
    z-index: 102;
    margin-left: -95%;
    transition: margin-left 0.5s;
  }

  #d-chat-list-side.d-chat-list-active {
    margin-left: 0;
  }

  #d-chat-body {
    height: 65vh !important;
  }

  .d-chatmessage {
    max-width: 55vw !important;
  }


  #d-side {
    width: 100% !important;
  }

  #d-header-menu-mobile {
    background-color: #404e67;
    height: 50px;
    line-height: 50px;
    z-index: 102 !important;
  }

  #d-header-menu {
    top: 50px !important;
    position: fixed;
    left: 0;
    right: 0;
    display: none;
    z-index: 90 !important;
  }

  #d-header-menu.d-header-menu-active {
    display: block !important;
  }

  .d-header-menu-mobile-button {
    color: #ffffff;
    padding: 20px;
    font-size: 20px;
  }

  .d-header-menu-mobile-button:hover,
  .d-header-menu-mobile-button:focus {
    color: #e4e7ed;
    text-decoration: none;
  }

  .d-side-menu {
    width: 70% !important;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    z-index: 101;
    margin-left: -70%;
    transition: margin-left 0.2s;
  }

  .d-side-menu.d-side-menu-active {
    margin-left: 0;
  }

  .d-side-menu-logo-container {
    display: none;
  }

  #d-side-menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .d-search-active#d-search {
    width: 120px !important;
  }
}

#d-chat-list-button-side-call {
  position: fixed;
  top: 140px;
  left: 20px;
  font-size: 18px;
  border: 1px solid #ff7588;
  padding: 8px 20px;
  border-radius: 5px;
  background: #fff;
  color: #ff7588;
  z-index: 100;
  box-shadow: 0 0 10px -5px #555555;
}

#d-chat-list-body {
  color: #404e67;
  height: 76.6vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.d-chat-list {
  padding: 10px;
  border-right: 2px solid #ffffff;
}
.d-chat-list-message {
  margin-top: 4px;
}
.d-chat-list:hover,
.d-chat-list-active {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border-right: 2px solid #00b5b8 !important;
}
.d-chat-list-time {
  color: #00b5b8;
  font-size: 13px;
  font-weight: 500;
}

.d-chat-list-unread {
  background-color: #00b5b8;
  color: #ffffff;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
}

#d-chat-list-container {
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #e4e7ed;
  overflow: auto;
  padding-bottom: 20px;
}
#d-chat-body {
  background-color: #ffffff;
  height: 70vh;
  overflow-y: auto;
}
#d-chat-body.d-chat-body-readonly {
  height: 92vh;
}

#d-chat-submit-container {
  padding-bottom: 20px;
  min-height: 160px;
}

#d-chat-submit-container .uk-tab li.uk-active a {
  border: 1px solid #e4e7ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #f5f7fa;
  color: #4e5154;
}

#d-chat-submit-container .uk-switcher li {
  padding: 0 10px;
}

.d-chatmessage {
  position: relative;
}

.d-chatmessage > .d-chatmessage-date {
  position: absolute;
  margin-top: -25px;
  left: 0;
  color: #999;
  font-size: 10px;
  z-index: 2;
  width: 100px;
}

.d-chatmessage > .d-chatmessage-read { 
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -18px;
  color: #999;
  font-size: 11px;
  z-index: 2;
  width: 100px;
}

.d-chatmessage-you {
  display: inline-block;
  padding: 10px;
  background-color: #edeef0;
  color: #404e67;
  text-align: left;
  border-radius: 5px;
}

.d-chatmessage-you .d-chatfood-bold,
.d-chatmessage-you .d-chatwound-bold {
  color: #00b5b8 !important;
  font-weight: bold;
}

.d-chatmessage-you a {
  color: #2c77f4;
  text-decoration: underline;
}

.d-chatmessage-me {
  display: inline-block;
  padding: 10px;
  background-color: #00b5b8;
  color: #fff;
  text-align: left;
  border-radius: 5px;
}
.d-chatfood-bold,
.d-chatwound-bold {
  font-weight: bold;
  font-size: 13px;
}
.d-chatmessage-me .d-chatfood-bold,
.d-chatmessage-me .d-chatwound-bold {
  color: #404e67 !important;
  font-weight: bold;
}

.d-chatmessage-me a {
  color: #FFF;
  text-decoration: underline;
}

.d-chatmessage-row {
  margin-bottom: 60px;
  position: relative;
}

.d-chatmessage-row > *,
.d-chatmessage-row > * > * {
  vertical-align: top !important;
  display: inline-block !important;
  position: relative !important;
  white-space: pre-line;
  word-break: break-word;
}


#smcreport-table td {
  border-right: 1px solid #CCC !important;
  font-size: 1.2rem !important;
}

#smcreport-table th {
  font-weight: bold;
  font-size: 1.2rem !important;
}

@media only screen and (max-width: 767px) {
  .d-chatmessage-row .d-chat-name-container {
    top: -50px !important;
  }

  .d-chatmessage-row {
    margin-bottom: 80px !important;
  }
}

.d-chatmessage-row .d-chat-name-container {
  position: absolute !important;
  color: #38445a;
  top: -30px;
  left: 80px;
  font-size: 13px;
  min-width: 50vw;
}

.d-chat-video-button {
  padding: 4px 5px;
  font-size: 12px;
  cursor: pointer;
}

.d-chat-video-button:hover {
  color: #ffffff;
}

.d-chatmessage-food-consult-rating-container,
.d-chatmessage-food-consult-rating-container .uk-button.input-group-addon,
.d-chat-video-button,
.d-chatmessage-wound-consult-rating-container .uk-button.input-group-addon {
  background-color: #009c9f !important;
  color: #fff;
  border-radius: 5px;
}

.d-risk-logo-container {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  text-align: center;
  line-height: 60px;
  margin: 10px;
}

.d-risk-logo {
  width: 40px;
  height: auto;
  margin: auto;
}

#d-side {
  width: 240px;
}

#d-search-result-container {
  width: 80%;
  max-width: 320px;
}

#d-search-result-container > div {
  padding: 4px 10px;
}

#d-search-result-container > div.d-label {
  font-size: 13px;
  color: #babfc7;
}
#d-search-result-container > div.d-label:not(:first-child) {
  margin-top: 15px;
}

#d-search-result-container > div:not(.d-label):hover {
  cursor: pointer;
  background-color: #f5f7fa;
  color: #1e2122;
}

#d-body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 30px;
  background-color: #f5f7fa;
}

.d-footer {
  padding: 5px 1.5rem;
  background-color: #fff;
  border-top: 1px solid #e4e7ed;
  color: #90a4ae;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.d-chat-profile-risk {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 36px;
}

.d-chat-profile-risk img {
  width: 24px;
}

.d-chat-history-label {
  background-color: #404e67;
  color: #fff;
  font-weight: 400;
  font-size: 85%;
  padding: 2px 4px;
  border-radius: 4px;
  margin-bottom: 40px;
  display: inline-block;
}

#d-chat-list-header {
  padding: 20px;
}

.d-risk-green {
  background-color: #16d39a;
}

.d-risk-yellow {
  background-color: #f1ee7d;
}

.d-risk-orange {
  background-color: #ffa87d;
}

.d-risk-red {
  background-color: #ff7588;
}

.d-submit-food-upload-button {
  border: 1px solid #babfc7;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  display: block;
  text-align: center;
}

.d-side-menu-logo-container {
  padding: 15px 15px 0 15px;
  background-color: #404e67;
}

.d-side-menu-title-container {
  padding: 20px 20px 0px 20px;
  color: #dcdcdc;
  font-weight: 500;
  font-size: 0.9rem;
}

.d-card-small {
  border: 1px solid #ccc;
  height: 70px;
  border-radius: 5px;
}

.d-card-small .d-card-small-inside {
  background-color: #ccc;
  color: #fff;
}

.d-card-small .d-card-small-icon {
  line-height: 70px;
  font-size: 20px;
  font-weight: 500;
}

.d-card-small .d-card-small-content {
  padding: 0 10px;
}

.d-card-small-datestart.d-card-small {
  border: 1px solid #00b5b8;
}

.d-card-small-datestart.d-card-small .d-card-small-inside {
  background-color: #00b5b8;
}

.d-card-small-datestop.d-card-small {
  border: 1px solid #ff7588;
}

.d-card-small-datestop.d-card-small .d-card-small-inside {
  background-color: #ff7588;
}
.d-card-small-people {
  background-color: #98f4d8;
}
.d-card-small-people.d-card-small {
  border: 1px solid #16d39a;
}

.d-card-small-people.d-card-small .d-card-small-inside {
  background-color: #16d39a;
}

.d-progress {
  background-color: #e4e7ed;
  height: 7px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.d-progress-inner ​ {
  width: 0;
  transition: width 0.4s;
}

.d-progress-inner {
  background-color: #00b5b8;
  height: 7px;
  border-radius: 4px;
}

.d-table-icon-container {
  padding: 8px 0;
}
.d-risk-label {
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
}

.d-active-user-row-header {
  font-weight: bold !important;
}

.d-active-user-row-data-today-active-user {
  font-size: 24px;
  color: #ff7588;
}

.d-active-user-row-data-today-active-user-date {
  font-size: 14px;
  color: #ff7588;
}

.d-active-user-row-data-avg-active-user {
  font-size: 24px;
  color: #00b5b8;
}

.d-smart-health-chat-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
}

.d-smart-health-chat-content {
  padding: 5px 10px;
  background-color: #edeef0;
  display: inline-block;
  border-radius: 5px;
  color: #404e67;
  font-size: 14px;
}

.d-smart-health-chat-arrow-left,
.d-chat-arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #edeef0;
  position: absolute;
  margin-left: -5px;
  margin-top: 8px;
}

.d-chat-arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #00b5b8;
  position: absolute;
  margin-top: 8px;
}

.d-chat-history-more {
  text-decoration: underline;
  padding: 10px;
  margin-top: 10px;
  display: inline-block;
  color: #90a4ae;
  font-size: 12px;
  transition: color 0.5s;
}

.d-chat-history-more:hover {
  color: #006a6c;
}

.d-smart-health-chat-by {
  padding: 5px 10px;
  background-color: #edeef0;
  display: inline-block;
  border-radius: 5px;
  color: #404e67;
  font-size: 14px;
  margin-top: 10px;
}

.d-smart-health-chat-fromnow {
  padding: 10px;
  text-align: center;
  color: #babfc7;
  font-size: 12px;
  margin-top: 10px;
}

.d-icon-rating {
  font-size: 14px;
}

#d-table-group-of-user-container {
  font-size: 20px;
  margin-top: 10px;
}

#d-search-trigger {
  cursor: pointer;
}

#d-search {
  width: 0px;
  display: inline-block;
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0 !important;
  height: 24px;
  padding: 4px 0;
  transition: width 0.5s;
  transition: padding 0.5s;
}

.d-search-active#d-search {
  width: 200px;
  padding: 4px 10px;
}

.d-search-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.d-header-menu-member-image {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 20px;
  vertical-align: middle;
}

.d-header-menu-more {
  font-size: 10px;
  font-weight: bold;
}

.d-button-group > *,
.d-button-group > * > *,
.d-button-group > .uk-button,
.d-button-group > * > .uk-button {
  border-radius: 0 !important;
}

.d-button-group > *:first-child,
.d-button-group > * > *:first-child,
.d-button-group > .uk-button:first-child,
.d-button-group > * > .uk-button:first-child {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.d-button-group > *:last-child,
.d-button-group > * > *:last-child {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.d-button-header-sub {
  border-color: #009c9f !important;
  color: #009c9f !important;
  transition: background-color 0.5s;
}

.d-button-header-sub:hover {
  background-color: #009c9f !important;
  color: #ffffff !important;
}

.d-dropdown-card {
  padding: 10px 0 !important;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.d-dropdown-card > a {
  color: #2a2e30;
  display: block;
  padding: 4px 20px;
  font-size: 14px;
}
.d-dropdown-card > a:hover {
  background-color: #f5f7fa;
  color: #1e2122;
  text-decoration: none;
}

.d-user-profile-card-link:first-child {
  border-top: 1px solid #e4e7ed;
}

.d-user-profile-card-link {
  color: #404e67;
  display: block;
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 1px solid #e4e7ed;
}

.d-user-profile-card-link.d-link-active {
  background-color: #f5f7fa;
  border-top: 1px solid #e4e7ed;
  color: #3369fe;
}

.d-user-profile-card-link:hover {
  text-decoration: none;
  color: #3369fe;
}

.uk-button {
  display: inline-block;
  font-weight: 400;
  color: #404e67;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  border: 1px solid transparent;
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  line-height: 1 !important;
  border-radius: 0.25rem !important;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  text-transform: none !important;
}

.uk-card.uk-card-default {
  border: 1px solid #404e67;
}

.uk-card.uk-card-default > .d-card-header {
  background-color: #404e67;
  color: #ffffff;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.uk-button-default {
  border: 1px solid #e5e5e5 !important;
}

.uk-button-default:hover {
  background-color: #edeef0 !important;
}

.uk-button-success {
  color: #ffffff !important;
  background-color: #16d39a;
  border-color: #16d39a;
}

.uk-button-primary {
  border-color: #008385 !important;
  background-color: #00b5b8 !important;
  color: #ffffff !important;
  text-decoration: none !important;
}

.uk-button-primary.a{
  text-decoration: none;
  color: #FFFFFF;
}

.uk-button-primary:hover {
  border-color: #009c9f !important;
  background-color: #00ced2 !important;
  color: #fff !important;
}

.uk-button-primary:focus,
.uk-button-primary:active {
  border-color: #008385 !important;
  background-color: #006a6c !important;
  color: #fff !important;
}

.uk-button-danger {
  background-color: #ff7588 !important;
  border-color: #ff425c !important;
  color: #fff !important;
}

.uk-button-danger:hover {
  background-color: #ff4f67 !important;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: 0;
  background-color: #fff;
  color: #666;
  border-color: #008385 !important;
}

.uk-tab li a {
  text-transform: none;
}
.uk-tab li.uk-active a {
  border: 1px solid #4e5154;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #fff;
  color: #4e5154;
}

.uk-text-success {
  color: #16d39a !important;
}

.uk-text-danger {
  color: #ff7588 !important;
}

.uk-text-primary {
  color: #2f94f3;
}

.uk-text-muted {
  color: #babfc7;
}

.uk-notification-message {
  background-color: #009c9f !important;
  color: #fff !important;
  border-radius: 10px;
  text-align: center;
}

.uk-modal-full.uk-open {
  background-color: rgb(0, 0, 0, 0.8);
}

.uk-sticky-fixed {
  z-index: 100 !important;
}

.d-user-last-advice-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.d-chatbox-chatlist-person-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.d-chatbox-chatlist-person-image-online {
  border: 5px solid #16d39a;
}

.d-header-sub-title {
  font-weight: 500;
  font-size: 22px;
}

.d-header-sub-breadcrumb a {
  font-size: 13px;
  font-weight: 400;
  color: #1e87f0;
}

.d-header-sub-breadcrumb a:hover {
  color: #2c77f4;
}

.d-noti-label {
  background-color: #ff7588;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 500;
}

.d-noti-list-icon {
  background-color: #00bcd4;
  color: #ffffff;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 18px;
  border-radius: 19px;
  display: block;
}

.d-noti-list-caption {
  margin-top: 3px;
  color: #404e67 !important;
}

#d-noti-header-badge {
  background-color: #ff7588;
  color: #ffffff;
  height: 18px;
  width: 18px;
  display: block;
  position: absolute;
  top: 0;
  text-align: center;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 9px;
  z-index: 2;
}

#d-noti-more {
  color: #2a2e30;
  display: block;
  padding: 4px 20px;
  font-size: 14px;
}

#d-noti-more:hover {
  background-color: #f5f7fa;
  color: #1e2122;
  text-decoration: none;
}

#d-table-personal-information td {
  padding: 4px 10px;
}

#d-table-personal-information td:last-child {
  text-align: right;
}

.d-fullscreen-element {
  position: fixed !important;
  z-index: 20000 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.d-button-edit {
  cursor: pointer;
}

.d-button-edit:hover {
  color: #008385;
}

.d-button-submit {
  padding: 4px 0 4px 8px !important;
}

.d-button-submit:hover {
  color: #008385;
}

#d-sugar-time-container {
  min-width: 340px;
}

#d-sugar-time-container > div > div > div {
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-top: 1px solid #c3c3c3;
  cursor: pointer;
}

#d-sugar-time-container
  > div
  > div
  > div:not(.d-sugar-time-button-active):hover {
  background-color: #f5f7fa;
}

.d-sugar-time-button-active {
  background-color: #008385;
  color: #fff;
}

#d-sugar-time-container > div > div:nth-child(2) > div {
  height: 40px;
  line-height: 40px;
  text-align: left;
  border-left: 1px solid #c3c3c3;
  padding-left: 10px;
}

#d-consult-profile-overview > div {
  padding: 5px 0;
}

.d-report-table thead tr th {
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid #e4e7ed;
  border-left: 1px solid #e4e7ed;
  font-size: 12px;
}
.d-report-table thead th:last-child {
  border-right: 1px solid #e4e7ed;
}

.d-report-table td {
  text-align: center;
  border-left: 1px solid #e4e7ed;
}

.d-report-table td:last-child {
  border-right: 1px solid #e4e7ed;
}

.d-report-table tr td:first-child {
  text-align: left;
}

.d-report-table tbody tr:first-child td {
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
  width: 150px;
}

.d-report-table tbody tr:last-child td {
  border-bottom: 1px solid #e4e7ed;
}

#d-data-upload-tab-header > .d-data-upload-tab-header-lihk:first-child {
  border-top-left-radius: 4px;
}

#d-data-upload-tab-header > .d-data-upload-tab-header-lihk:last-child {
  border-top-right-radius: 4px;
}
#d-data-upload-tab-header {
  width: 500px;
  overflow-x: auto;
}
.d-data-upload-tab-header-lihk:hover {
  color: #3369fe;
  text-decoration: inherit;
}

.d-data-upload-tab-header-lihk {
  border: 1px solid #e4e7ed;
  padding: 2px 10px;
  line-height: 30px;
  font-size: 11px;
  display: inline-block;
  background-color: #e4e7ed;
  color: #666;
}

.d-data-upload-tab-header-lihk-active {
  color: #000;
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

#d-data-upload-tab-body {
  margin-top: -1px;
  padding: 20px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  border-top-left-radius: 0px;
}

.d-note-border-bottom {
  line-height: 30px;
  border-bottom: 1px dashed #000;
  display: block;
  width: cal(100%-15px);
  min-height: 30px;
  margin-bottom: 5px;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

#d-print-user-health-book {
  font-size: 14px;
}
#d-print-user-health-book .d-text-blue,
#d-print-user-health-book .d-text-purple {
  line-height: 26px;
  font-size: 15px;
}
#d-print-user-health-book td {
  padding: 2px 10px;
}

@media print {
  #d-print-container {
    display: block !important;
  }
}

.d-user-health-book-container {
  border: 1px solid #000;
  padding: 10px;
  padding-top: 12px;
  margin-bottom: 10px;
}

#d-print-user-health-book-container {
  display: none;
}

.uk-button.input-group-addon {
  background-color: #eee !important;
}

.d-row-data-history {
  padding: 10px;
  border-top: 1px solid #e4e7ed;
}

.d-row-data-history-edit-container {
  border: 1px solid #e4e7ed;
  margin: 5px;
  padding: 15px;
}
.d-form-login-vector-cirlce {
  border-radius: 8px;
  height: 16px;
  width: 16px;
  background-color: #dbdbdb;
  display: inline-block;
  position: absolute;
  top: -8px;
}

.d-form-login-vector-cirlce.d-form-login-vector-cirlce-active {
  background-color: #2da6ed;
}

.d-form-login-body {
  min-height: 100vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.d-form-login-container {
  border: none !important;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px !important;
  font-family: "Prompt", sans-serif;
  color: #111 !important;
  line-height: 18px;
}

.d-font-prompt {
  font-family: "Prompt", sans-serif !important;

}



.d-form-login-container .uk-textarea {
  background-color: #eeeeee;
  border-color: #dbdbdb;
  margin-bottom: 8px;
  font-size: 16px;

}

.d-form-login-container .uk-input,
.d-form-login-container .uk-select  {
  background-color: #eeeeee;
  border-color: #dbdbdb;
  height: 30px !important;
  line-height: 30px;
  margin-bottom: 8px;
  font-size: 16px;
}
.d-form-login-container .uk-input:focus,
.d-form-login-container .uk-select:focus,
.d-form-login-container .uk-textarea:focus {
  border-color: #00428c !important;
}
.d-form-login-container .uk-input > label {
  line-height: 26px !important;
  height: 26px !important;
  font-size: 10px;
  background-color: #fff;
  margin-right: -8px;
  margin-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  position: relative;
  z-index: 1;
  vertical-align: middle;
}

.d-form-login-container .uk-button-primary {
  background-color: #00428c !important;
  font-size: 14px !important;
  height: 36px !important;
}

.d-form-login-container .d-text-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

.d-form-login-container .d-input-otp.uk-input {
  width: 40px;
  height: 40px !important;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.d-chatmessage-me .d-table-diabates-data th {
  color: #fff;
}

.d-table-diabates-data {
  min-width: 500px;
}

.d-table-diabates-data td,
.d-table-diabates-data th {
  border-top: 1px solid #e4e7ed;
  border-left: 1px solid #e4e7ed;
  font-size: 13px;
}

.d-table-diabates-data td:last-child,
.d-table-diabates-data th:last-child {
  border-right: 1px solid #e4e7ed;
}

.d-table-diabates-data tr:last-child td,
.d-tabledialysis-volume-summary-td {
  border-bottom: 1px solid #e4e7ed;
}

.d-table-diabates-data th {
  padding: 2px !important;
  text-align: center !important;
  font-size: 12px !important;
}

.d-table-diabates-data td:first-child > div {
  padding: 2px;
}
.d-table-diabates-data td {
  padding: 0 !important;
  text-align: center;
}

.d-table-diabates-data td .uk-input,
.d-table-diabates-data td .uk-select {
  height: 24px !important;
  font-size: 13px;
  line-height: 24px;
  border-radius: 0 !important;
  border: 0;
  min-width: 60px;
}

.d-border-bottom {
  border-bottom: 1px solid #EEE;
}

.d-language-login-select-container {
  padding-top: 20px;
  font-size: 10px;
  margin-right: -20px;
}

.d-form-login-title {
  color: #00428c;
  font-family: "Prompt", sans-serif !important;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.d-popup-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.d-popup-body {
  background-color: #FFF;
  width: 80vw;
  padding: 20px;
  margin: auto;
}

.d-popup-body.d-popup-body-small {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.d-campaign-icon {
  height: 48px !important;
}

.d-work-health-record-container td textarea {
  margin: 0 0;
}

.d-work-health-record-container textarea {
  margin: 10px 0;
}

.d-work-health-record-container .uk-input {
  border-radius: 0 !important;
}

#d-wound-tab {
  padding:0 10px;
  border-bottom: 1px solid #EEEEEE;
}

#d-wound-tab a {
  text-decoration: underline;
}

#d-wound-tab.uk-subnav-pill>.uk-active>a {
  background-color: #008385;
}

.d-dialysis-tab-button-container {
  border-bottom: 1px solid #008385;
  margin-bottom: 10px;
}

.d-dialysis-tab-button {
  background: none;
  border: 0;
  color: #555555;
  padding: 3px 10px;
  font-size: 13px;
  margin-bottom: -1px;
  z-index: 1;
}

.d-dialysis-tab-button-active {
  border: 1px solid #008385;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #f5f7fa;
  color: #008385;
}

.d-dialysis-history-card {
  padding: 10px;
  border: 1px solid #008385;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 13px;
}

.d-form-healthnot label {
  font-weight: normal;
}

.d-table-border-vertical tr td:not(:last-child),
.d-table-border-vertical tr th:not(:last-child) {
  border-right: 1px solid #CCC;
}

.d-teleconsult-thumbnail-container {
  position: relative;
}

.d-teleconsult-thumbnail-unread {
  position: absolute;
  top: -5px;
  right: -8px;
  display: inline-block;
  background-color: #ff7588;
  color: #FFF;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.d-teleconsult-message-container {
  padding:  10px;
  white-space: pre-wrap;
}

.d-teleconsult-message-container .d-teleconsult-member-label {
  color:#666;
}

.d-teleconsult-message-container .d-teleconsult-member-label:hover {
  opacity: 0.8;
  cursor: pointer;
}

.d-teleconsult-message-container {
  border: 2px solid #bcbcbc;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 16px;
  position: relative;
  box-shadow: 2px 2px 5px -2px #CCC;
  border-radius: 5px;
}

.d-teleconsult-line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid #EEE;
}

.d-teleconsult-message-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 10px 5px 0;
  border-color: transparent #bcbcbc transparent transparent;
  position: absolute;
  left: -11px;
  top: 3px;
}

.d-teleconsult-media-delete-button {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 10px !important;
  background-color: #ff425c !important;
  color: #FFF !important;
  z-index: 2;
  width: 18px;
  height: 18px;
  line-height: 16px !important;
  text-align: center;
  border-radius: 50% !important;
}

.d-teleconsult-media-delete-button:hover {
  opacity: .8;
}

#react-select-2-listbox {
  z-index: 10;
}

#member-search-container {
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #CCC;
  background-color: #FFF;
  min-width: 300px; 
  margin-top: 10px;
  z-index: 10;
  top: 60px;
}

#member-search-container > div {
  border-bottom: 1px solid #CCC;
  padding-top: 2px;
  padding-bottom: 2px;
}

#member-search-container a {
  background-color: #16d39a;
  color: #FFF;
  display: inline-block;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
}

#member-search-container a:hover {
  opacity: 0.7;
}

#member-search-container svg {
  height: 14px;
  width: 14px;
}

@keyframes flash {
	0% {
		opacity: 0.3;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.3;
	}
}

.d-videocall-card {
  border-radius: 10px !important;
} 
.d-videocall-button {
  width: 100%;
  background-color: #3067ff;
  border:0;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  color: #FFF;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  padding-left: 40px;
}
.d-videocall-button:hover {
  opacity: 0.9;
  transition: opacity .4s;
}

.d-videocall-button-icon {
  margin-right: 10px;
  margin-top: 14px;
  font-size: 24px;
  float: right;
  animation-name: flash;
	animation-duration: 0.4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}


.d-videocall-person-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #16d39a;
  text-align: center;
  display: inline-block;
}

.d-videocall-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.tox-tinymce {
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px !important;
}

#hiv-modal-form-v2 .uk-modal-dialog {
  min-height: 100vh;
}

#hiv-stigma-modal-form .uk-modal-dialog {
  min-height: 100vh;
}

.d-link {
  cursor: pointer;
}

.d-link:hover {
  opacity: 0.9;
}

.d-link:active {
  opacity: 0.6;
}


.knowledge-preview {
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.knowledge-container {
  max-width: 600px;
}

.knowledge-preview-container {
  border: 1px solid #404e67;
  background-color: #404e67;
  color: #FFF;
  text-decoration: none;
}


.knowledge-preview-container a {
  text-decoration: none;
  font-weight: bold;
}

.knowledge-preview-container a:hover {
  color: #FFF;
}

.d-knowledge-list {
  padding: 5px 10px;
  border-bottom: 1px solid #CCC;
  cursor: pointer;
}

.d-knowledge-list:hover {
  background-color: #F1F2F3;
}

.d-knowledge-media-container {
  padding: 5px;
  background-color: #ffda7d;
  margin-top: 5px;
  position: relative;
}

.d-knowledge-media-video-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.d-knowledge-media-title {
  padding: 5px;
  padding-top: 10px;
  font-weight: bold;
  text-align: center;
  color: #000;
  text-decoration: underline;
}

#d-knowledge-container {
  background-color: #FFF;
}

#d-knowledge-title {
  background-color: #404e67;
  color: #FFF;
}

.d-member-chat-button, 
.d-consult-chat-button {
  border-radius: 5px;
}

.d-member-chat-button:hover,
.d-consult-chat-button:hover {
  opacity: 0.9;
}

#d-register-table-by-year-campaign-dropdown {
  min-width: 300px;
}

#d-register-table-by-year-campaign-select-button { 
  color: #000;
}

#d-chat-list-body .uk-label {
  white-space: wrap;
  margin-bottom: 5px;
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blinker 0.4s linear infinite;
}